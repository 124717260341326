.content {
    display: flex;
    flex-direction: column;    /* Stack the header and social-links vertically */
    align-items: center;
    width: 100%;               /* Ensure the container takes up full width */
    justify-content: center;
    flex-grow: 1;  
    position: relative;
    gap: 32px;
} 

.content .description {
  max-width: 600px;
  width: 100%;
}

.profile-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-top: -75px;
  margin-bottom: 16px;
  border: 4px solid var(--container-bg-light);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.profile-image:hover {
  transform: scale(1.05);
}

body[data-theme="dark"] .profile-image {
  border-color: var(--container-bg-dark);
}

.description {
  font-size: 1.1rem;
  line-height: 1.8;
  color: var(--text-color);
  margin: 0 auto;
  padding: 0 20px;
  text-align: justify;
  max-width: 600px;
  width: 100%;
  column-width: 300px;
  column-gap: 40px;
  column-fill: balance;
}

.description p {
  margin-bottom: 1em;
  text-indent: 0;
  break-inside: avoid;
}

@media (max-width: 768px) {
  .content {
    gap: 24px;
  }

  .profile-image {
    width: 120px;
    height: 120px;
    margin-top: -60px;
  }

  .description {
    font-size: 1rem;
    padding: 0 16px;
    max-width: 100%;
    column-width: auto;
    column-gap: 0;
  }
}