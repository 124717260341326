.description-section {
  width: 100%;
  margin-top: 16px;
  background-color: var(--website-bg);
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.description-section h2 {
  font-size: 1.5rem;
  margin-bottom: 24px;
  color: var(--text-color);
  font-weight: 600;
}

.description-content {
  font-size: 1.1rem;
  line-height: 1.8;
  color: var(--text-color);
  text-align: justify;
  column-width: 300px;
  column-gap: 40px;
  column-fill: balance;
}

.description-content p {
  margin-bottom: 1.5em;
  text-indent: 0;
  break-inside: avoid;
}

.description-content p:last-child {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .description-section {
    margin-top: 12px;
    padding: 20px;
  }

  .description-section h2 {
    margin-bottom: 20px;
    font-size: 1.4rem;
  }

  .description-content {
    font-size: 1rem;
    line-height: 1.7;
    column-width: auto;
    column-gap: 0;
  }

  .description-content p {
    margin-bottom: 1.2em;
  }
} 