.projects {
  width: 100%;
  margin-top: 24px;
  background-color: var(--website-bg);
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.projects h2 {
  font-size: 1.5rem;
  margin-bottom: 16px;
  color: var(--text-color);
}

.project-card {
  background-color: var(--container-bg);
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.project-card:last-child {
  margin-bottom: 0;
}

.project-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.project-card h3 {
  font-size: 1.2rem;
  margin-bottom: 8px;
  color: var(--text-color);
}

.project-card p {
  font-size: 0.95rem;
  line-height: 1.5;
  color: var(--text-color);
  margin-bottom: 16px;
}

.project-link {
  display: inline-block;
  padding: 8px 16px;
  background-color: #FDCE45;
  color: #000000;
  text-decoration: none;
  border-radius: 4px;
  font-size: 0.9rem;
  transition: opacity 0.2s ease;
}

.project-link:hover {
  opacity: 0.9;
}

@media (max-width: 768px) {
  .projects {
    margin-top: 20px;
    padding: 16px;
  }

  .project-card {
    padding: 16px;
  }
} 