:root {
  --website-bg: #ffffff;
  --container-bg: #f5f5f5;
  --text-color: #000000;
}

:root[data-theme="dark"] {
  --website-bg: #000000;
  --container-bg: #1a1a1a;
  --text-color: #ffffff;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--website-bg);
  color: var(--text-color);
  transition: background-color 0.3s ease, color 0.3s ease;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
}
