.social-links {
  width: 100%;
  margin-top: 16px;
  background-color: var(--website-bg);
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.social-links h2 {
  font-size: 1.5rem;
  margin-bottom: 16px;
  color: var(--text-color);
  font-weight: 600;
}

.social-links-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
  flex-wrap: wrap;
  width: 100%;
}

@media (max-width: 768px) {
  .social-links {
    margin-top: 12px;
    padding: 16px;
    gap: 20px;
  }

  .social-links-container {
    gap: 20px;
  }
}