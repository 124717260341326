.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: var(--text-color);
  font-family: Arial, sans-serif;
  justify-content: space-between;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  overflow-x: hidden;
}

.content-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 16px;
  min-height: calc(100vh - 60px); /* Subtract footer height */
  box-sizing: border-box;
}

.main-content {
  background-color: var(--container-bg);
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  box-sizing: border-box;
  width: 100%;
  max-width: 600px;
  margin: 16px auto;
}

@media (max-width: 768px) {
  .content-wrapper {
    padding: 0 12px;
    min-height: calc(100vh - 50px); /* Slightly smaller footer on mobile */
  }
  
  .main-content {
    padding: 16px;
    margin: 12px auto;
  }
}