.bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 800px;
    margin: 0 auto;
    padding: 16px;
    padding-bottom: calc(16px + env(safe-area-inset-bottom));
    box-sizing: border-box;
    width: 100%;
    position: sticky;
    bottom: 0;
    z-index: 10;
}

.bottom .social-links {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-bottom: 16px;
}

@media (max-width: 768px) {
    .bottom {
        padding: 12px;
        padding-bottom: calc(12px + env(safe-area-inset-bottom));
    }
}